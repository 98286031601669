import React, { useRef, useContext, useEffect, useState, useCallback } from "react";
import { motion } from "framer-motion";
import { ThemeContext } from "../../App";
import axiosProvider from "../../core/axios";
import { useNavigate } from "react-router";
import Metadata from "../../components/metadata";
import { imageZoomVariants, fadeInLeftAnimationVariants } from "../../components/motionVariant";


const About = () => {
    const scrollRef = useRef(null);
    const navigate = useNavigate()
    const { theme } = useContext(ThemeContext);
    const [data, setData] = useState(null);
    const [metadata] = useState({
        title : "A propos - En savoir plus sur Dj-Tdy",
        description : "Dive into the World of Python Web Development and Unleash the Power of Creativity and Functionality in Every Line of Code.",
        generator : "Made by fujyn",
        keywords : "DJ,Dj à domicile,Fête,Mariages,Baptême,soirée, animation",
        canonical : "http://www.bestexemple.com/canonical",
        og_locale : "en_GB",
        og_locale_alternate : "fr_fr",
        og_type : "article",
        og_title : "The Art of Python Web Development: Crafting Dynamic Digital Experiences",
        og_description : "Dive into the World of Python Web Development and Unleash the Power of Creativity and Functionality in Every Line of Code.",
        og_url : "https://www.thisisatest.com",
        og_image_url : "https://www.bestexemple.com/exempleimage.png",
        og_image_secure_url : "https://www.bestexemple.com/exempleimage.png",
        article_section : "Web development",
        article_publisher : "Test author",
        article_published_time : "2023-01-07T02:10:58.678Z",
        article_modified_time : "2023-01-07T02:10:58.678Z",
        twitter_title : "The Art of Python Web Development: Crafting Dynamic Digital Experiences",
        twitter_description : "Dive into the World of Python Web Development and Unleash the Power of Creativity and Functionality in Every Line of Code.",
        twitter_site : "@testuser",
        twitter_card : "summuary_test",
        twitter_image : "https://www.bestexemple.com/exempleimage.png",
        twitter_creator : "@testuser",
        twitter_cta : "En savoir plus",
        twitter_label_1 : "Ecrit par",
        twitter_data_1 : "DJ-Tdy Team",
        twitter_label_2 : "Temps de lecture",
        twitter_data_2 : "10 minutes",
    })

    const fetchData = useCallback(async () => {
        await axiosProvider.get("/core/about").then((response) => {
            setData(response.data);
        }).catch((error) => {
            navigate("/404");
        });
    }, [navigate]);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, [fetchData]);

    const checkTheme = () => {
        return theme === "light" ? "var(--regular-white)" : "var(--dark-grey)";
    };

    return (
        <>
            {data ? (
                <>
                    {/*-------------------------------- SEO --------------------------------*/}
                    <Metadata metadata={metadata} type="page" />
                    {/*-------------------------------- Banner --------------------------------*/}
                    <div className="container-x-small bg-white" ref={scrollRef}>
                        <div className="ban-img-wrapper-small">
                            <motion.div variants={imageZoomVariants} initial={"initial"} whileInView={'animate'} transition={{duration: 100, repeat: Infinity,}} className="ban-img">
                                <img className="img-cover" src="/images/platine-7.jpg" alt="Banner" />
                            </motion.div>
                        </div>
                        <div className="ban-content-small">
                            <div className="gutter-large">
                                <div className="grid-1-2">
                                    <div className="grid-item">
                                        <div className="full-width">
                                            <h1 className="big-text bold-text white-text pad-b-m">A propos de DJ-TDY</h1>
                                            <p className="large-text light-text white-text pad-b-xl">Les formules et options sont là pour aider à choisir la manière dont votre évènement sera présenté et immortalisé, choisissez la formule qui vous correspond pour passer un moment inoubliable.</p>
                                        </div>
                                    </div>
                                    <div className="grid-item">
                                        <div className="full-width">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="back-container">
                            <svg xmlns="http://www.w3.org/2000/svg" id="back-shape" viewBox="0 0 1920 780.981">
                                <g id="shape_normal" transform="translate(0 -300)">
                                    <path id="last" d="M0-214.839s873.847,174.089,1353.847-21.4S1920-916.781,1920-916.781V-135.8H0Z" transform="translate(0 1216.781)" opacity="0.47"/>
                                    <path id="second" d="M0-214.839s873.847,99.089,1353.847-21.4S1920-806.781,1920-806.781V-135.8H0Z" transform="translate(0 1216.781)" opacity="0.65"/>
                                    <path id="first" d="M0-214.839s873.847,99.089,1353.847-21.4S1920-696.781,1920-696.781V-135.8H0Z" transform="translate(0 1216.781)" fill={checkTheme()}/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    {/*-------------------------------- Image Slider --------------------------------*/}
                    <div className="container-x-small bg-white">
                        <div className="spacer"></div>
                        <div className="gutter">
                            <div className="grid-2-4">
                                <motion.div variants={fadeInLeftAnimationVariants} initial={"initial"} whileInView={"animate"} transition={{duration:0.4, delay:0.6}} className="grid-item">
                                    <div className="card-img-r">
                                        <img className="img-cover" src="/images/event-animation-1.jpeg" alt="Event animation 1" />
                                    </div>
                                </motion.div>
                                <motion.div variants={fadeInLeftAnimationVariants} initial={"initial"} whileInView={"animate"} transition={{duration:0.6, delay:0.6}} className="grid-item">
                                    <div className="card-img-r">
                                        <img className="img-cover" src="/images/event-animation-2.jpeg" alt="Event animation 2" />
                                    </div>
                                </motion.div>
                                <motion.div variants={fadeInLeftAnimationVariants} initial={"initial"} whileInView={"animate"} transition={{duration:0.8, delay:0.6}} className="grid-item">
                                    <div className="card-img-r">
                                        <img className="img-cover" src="/images/event-animation-3.jpeg" alt="Event animation 3" />
                                    </div>
                                </motion.div>
                                <motion.div variants={fadeInLeftAnimationVariants} initial={"initial"} whileInView={"animate"} transition={{duration:1.0, delay:0.6}} className="grid-item">
                                    <div className="card-img-r">
                                        <img className="img-cover" src="/images/event-animation-4.jpeg" alt="Event animation 4" />
                                    </div>
                                </motion.div>
                            </div>
                        </div>
                        <div className="spacer"></div>
                    </div>
                    {/*-------------------------------- Mentality bloc --------------------------------*/}
                    <div className="container-small bg-white">
                        <div className="gutter-medium">
                            <div className="flex-col-center">
                                <div className="flex-row-center pad-t-xl pad-b-xl">
                                    <div className="icon-medium bg-icon-black">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 590 512">
                                            <path d="M0 32C0 14.3 14.3 0 32 0H160c17.7 0 32 14.3 32 32V416c0 53-43 96-96 96s-96-43-96-96V32zM223.6 425.9c.3-3.3 .4-6.6 .4-9.9V154l75.4-75.4c12.5-12.5 32.8-12.5 45.3 0l90.5 90.5c12.5 12.5 12.5 32.8 0 45.3L223.6 425.9zM182.8 512l192-192H480c17.7 0 32 14.3 32 32V480c0 17.7-14.3 32-32 32H182.8zM128 64H64v64h64V64zM64 192v64h64V192H64zM96 440a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/>
                                        </svg>
                                    </div>
                                    <h2 className="big-text bold-text black-text pad-l-m">La mentalité</h2>
                                </div>
                            </div>
                            <div className="flex-col-center pad-b-xl">
                                <p className="medium-text regular-text black-text pad-b-m">
                                    Nam imperdiet bibendum elit, a suscipit nisl hendrerit eu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Mauris a pretium orci, in maximus lorem. Phasellus malesuada diam a tristique ultrices. Integer ut luctus tellus. Donec sodales ultricies consequat. Ut pharetra bibendum purus a dignissim. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer posuere fermentum quam, at egestas nunc laoreet non. Ut purus arcu, lacinia ac eros sed, fermentum euismod odio.
                                </p>
                                <p className="medium-text regular-text black-text pad-b-xl">
                                    Nam imperdiet bibendum elit, a suscipit nisl hendrerit eu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Mauris a pretium orci, in maximus lorem. Phasellus malesuada diam a tristique ultrices. Integer ut luctus tellus. Donec sodales ultricies consequat. Ut pharetra bibendum purus a dignissim. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer posuere fermentum quam, at egestas nunc laoreet non. Ut purus arcu, lacinia ac eros sed, fermentum euismod odio.
                                </p>
                                <a href="/demande" className="btn btn-bordered btn-large btn-great">Animer vos évènements</a>
                            </div>
                        </div>
                        <div className="spacer"></div>
                    </div>
                    {/*-------------------------------- About Infos --------------------------------*/}
                    <div className="container bg-white">
                        <div className="grid-1-2">
                            <div className="grid-item">
                                <div className="gutter full-width">
                                    <div className="flex-col-start full-width pad-b-l">
                                        <div className="flex-row-start">
                                            <h2 className="big-text bold-text black-text pad-b-m">L'histoire de DJ-TDY</h2>
                                        </div>
                                        <p className="medium-text regular-text black-text pad-b-s">DJ et annimateur depuis de nombreuses années. J'assure l'annimation de tous types d'évènements (mariages, anniversaires, baptêmes, soirées particulières avec des thématiques diverses, karaoké).</p>
                                        <p className="medium-text regular-text black-text pad-b-l">Mon objectif premier c'est votre satisfaction. Je me donne ainsi les moyens de faire de votre évènement un moment mémorable à votre convenance et à votre image.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-item">
                                <div className="gutter full-width">
                                    <motion.div variants={fadeInLeftAnimationVariants} initial={"initial"} whileInView={"animate"} transition={{duration:0.6, delay:0.6}} className="card-img-display">
                                        <img className="img-cover" src="/images/djtdy-wide-pres.jpg" alt="DJ-TDY platine 2" />
                                    </motion.div>
                                </div>
                            </div>
                        </div>
                        <div className="spacer"></div>
                    </div>
                    {/*-------------------------------- Experience bloc --------------------------------*/}
                    <div className="container-small bg-white">
                        <div className="gutter">
                            <div className="flex-col-center pad-b-l">
                                <div className="flex-row-center pad-b-xl">
                                    <div className="icon-medium bg-icon-black">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path d="M288 0H160 128C110.3 0 96 14.3 96 32s14.3 32 32 32V196.8c0 11.8-3.3 23.5-9.5 33.5L10.3 406.2C3.6 417.2 0 429.7 0 442.6C0 480.9 31.1 512 69.4 512H378.6c38.3 0 69.4-31.1 69.4-69.4c0-12.8-3.6-25.4-10.3-36.4L329.5 230.4c-6.2-10.1-9.5-21.7-9.5-33.5V64c17.7 0 32-14.3 32-32s-14.3-32-32-32H288zM192 196.8V64h64V196.8c0 23.7 6.6 46.9 19 67.1L309.5 320h-171L173 263.9c12.4-20.2 19-43.4 19-67.1z"/>
                                        </svg>
                                    </div>
                                    <h2 className="big-text bold-text black-text pad-l-m">Experiences</h2>
                                </div>
                            </div>
                            <div className="flex-col-center">
                                <div className="grid-4 pad-b-l">
                                    {data.experiences.map((experience,i) => {
                                        return (
                                            <div className="grid-item" key={i}>
                                                <div className="mod-card">
                                                    <div className="mod-card-header">
                                                        <img className="img-cover" src="/images/platine.jpg" alt="article-1" />
                                                    </div>
                                                    <div className="mod-card-content">
                                                        <p className="large-text bold-text r-black-text pad-b-m">{experience.title}</p>
                                                        <div className="grid-3-5 pad-b-m">
                                                            {experience.categories ? experience.categories.map((category,i) => {
                                                                return (
                                                                    <div className="grid-item-fit" key={i}>
                                                                        <span className="tag tag-small tag-great marg-r-s">{category.title}</span>
                                                                    </div>
                                                                )
                                                            }) : ""}
                                                        </div>
                                                        <p className="medium-text regular-text r-black-text pad-b-s">{experience.short_desc}</p>
                                                    </div>
                                                    <div className="mod-card-footer">
                                                        <p className="small-text bold-text r-black-text">{experience.created_at}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="spacer"></div>
                    </div>
                </>
            ): (
                <>
                    <div className="container-primary bg-white">
                        <div className="flex-full-center full-height">
                            <div className="icon-big">
                                <motion.svg xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 422 422"
                                animate={{rotate:[0, 360] }}
                                transition={{
                                    type: "linear",
                                    duration: 0.5, 
                                    repeat: Infinity
                                }}>
                                    <g id="loading_icon" transform="translate(4730 -3101)">
                                        <path className="loading" id="Subtraction_2" data-name="Subtraction 2" d="M436.543,212.707h0l-72.556-.1c.008-.575.012-1.179.012-1.795A128.8,128.8,0,0,0,275.063,88.184V13.247l.422.086.134.027a200.468,200.468,0,0,1,72.07,30.326,202.154,202.154,0,0,1,73.023,88.677A200.476,200.476,0,0,1,432.457,170.2a203.034,203.034,0,0,1,4.1,40.62c0,.62,0,1.255-.009,1.889Z" transform="translate(-4744.552 3094.183)" />
                                        <circle id="Ellipse_25" data-name="Ellipse 25" cx="211" cy="211" r="211" transform="translate(-4730 3101)" fill="none"/>
                                    </g>
                                </motion.svg>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default About