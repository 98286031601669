import React from 'react'
import { motion } from "framer-motion";

const SPIN = ['left', 'right']
const COLOR = ['current', 'sand', 'great', 'danger']

const Projector = ({spin, color}) => {
    const checkSpin = SPIN.includes(spin) ? spin : SPIN[0]
    const checkColor = COLOR.includes(color) ? color : COLOR[0]

    const getRandomIntInclusive = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
    }
    

    const spinVariants = {
        left: { rotate: [10, -90, 10], transition: { duration: getRandomIntInclusive(4,8), repeat: Infinity, type: "easeInOut"} },
        right: { rotate: [10, 90, 10], transition: { duration: getRandomIntInclusive(4,8), repeat: Infinity, type: "easeInOut"} },
    }

    return (
        <div className={`projector-${checkSpin}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1399.154" height="1399.17" viewBox="0 0 1399.154 1399.17">
                <g id="projector" transform="translate(-503.463 4449.6)">
                    <motion.g id={`projector-rotate-${checkSpin}`} variants={spinVariants} animate={checkSpin}>
                        <g id="projector_mobile" transform="translate(1185.146 -3777.933)">
                            <path id="projector_base" d="M4.008,27.81,0,25.119V9.22l3.116-.931V7.138H1.768a1.745,1.745,0,0,1,0-3.49H3.116V0H32.459V3.648h1.348a1.745,1.745,0,0,1,0,3.49H32.459V8.277l3.2.944v15.9l-4.1,2.719A14.678,14.678,0,0,1,4.008,27.81Z" transform="translate(0 3.648)" fill="#4a4a4a"/>
                            <g id="shadows">
                                <rect id="Rectangle_110" data-name="Rectangle 110" width="1.665" height="10.072" transform="translate(16.995 6.82)" fill="#fff" opacity="0.34"/>
                                <path id="Path_67" data-name="Path 67" d="M0,0H1.665l.053,9.627a6.57,6.57,0,0,1-.726.38c-.416.186-.939.364-.939.364Z" transform="translate(28.336 5.155)" fill="#fff" opacity="0.34"/>
                                <path id="Path_66" data-name="Path 66" d="M0,0H1.665l-.16,10.279S1.073,10.128.7,9.957,0,9.6,0,9.6Z" transform="translate(5.575 5.155)" fill="#fff" opacity="0.34"/>
                                <path id="Path_68" data-name="Path 68" d="M0-10.612l3.121-.914.9,19.535L0,5.328Z" transform="translate(0 23.46)" fill="#fff" opacity="0.34"/>
                                <path id="Path_69" data-name="Path 69" d="M4.232-10.545l-3.139-.908L.13,8.1l4.1-2.7Z" transform="translate(31.423 23.392)" fill="#fff" opacity="0.34"/>
                            </g>
                            <ellipse id="Ellipse_27" data-name="Ellipse 27" cx="14.672" cy="3.648" rx="14.672" ry="3.648" transform="translate(3.116 0)" fill="#fff"/>
                            <ellipse id="Ellipse_28" data-name="Ellipse 28" cx="12.213" cy="3.014" rx="12.213" ry="3.014" transform="translate(5.575 0.634)" fill="#4a4a4a"/>
                            <path id="projector_brace" d="M-.356.856s.138,4.978,14.735,4.978,14.6-4.99,14.6-4.99" transform="translate(3.484 11.079)" fill="none" stroke="#fff" strokeWidth="0.5"/>
                        </g>
                        <g id="laser" transform="translate(1183.205 -4447.406)">
                            <path id="laser_flow" d="M-80,0h59.679L-38.066,667.827s.78,3.124-12.158,3.086-12.321-2.9-12.321-2.9Z" transform="translate(70 5.266)" className={`laser-${checkColor}`} opacity="0.52"/>
                            <ellipse id="laser_bean" cx="29.839" cy="7.46" rx="29.839" ry="7.46" transform="translate(-10 -2.194)" className={`laser-${checkColor}`}/>
                        </g>
                        <circle id="rotator" cx="699.577" cy="699.577" r="699.577" transform="translate(503.463 -4449.584)" fill="none"/>
                    </motion.g>
                    <g id="projector_base-2" data-name="projector_base" transform="translate(1179 -3756.584)">
                        <path id="Path_62" data-name="Path 62" d="M3.235,0H44.746l3.235,9.273H0Z" transform="translate(0 32.023)" fill="#4a4a4a"/>
                        <rect id="Rectangle_106" data-name="Rectangle 106" width="7.871" height="1.833" rx="0.916" transform="translate(20.051 30.19)" fill="#989898"/>
                        <g id="Group_268" data-name="Group 268" transform="translate(17.364 0)">
                            <path id="Path_63" data-name="Path 63" d="M-2.038,0H10.838s.415,14.049,0,19.957c-.28,2.619-3.323,3.676-3.323,3.676H1.285s-3-1.447-3.323-3.661C-2.453,14.065-2.038,0-2.038,0Z" transform="translate(2.222 6.624)" fill="#989898"/>
                            <circle id="Ellipse_26" data-name="Ellipse 26" cx="6.577" cy="6.577" r="6.577" transform="translate(0.099 0)" fill="#ccc"/>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default Projector