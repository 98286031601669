import React, { useRef, useContext, useEffect, useState, useCallback  } from "react";
import { motion, /* useScroll */ } from "framer-motion";
import { ThemeContext } from "../../App";
import SearchBar from "../../components/searchBar";
import { Link, useNavigate } from "react-router-dom";
import axiosProvider from "../../core/axios";
import Modal from "../../components/modal";
import Metadata from "../../components/metadata";
import { imageZoomVariants } from "../../components/motionVariant";


const Formulas = () => {
    const scrollRef = useRef(null);
    const navigate = useNavigate()
    const { theme } = useContext(ThemeContext);
    const [data, setData] = useState(null);
    const [pack, setPack] = useState({})
    const [metadata] = useState({
        title : "Formules - Les offres de Dj-Tdy",
        description : "Dive into the World of Python Web Development and Unleash the Power of Creativity and Functionality in Every Line of Code.",
        generator : "Made by fujyn",
        keywords : "DJ,Dj à domicile,Fête,Mariages,Baptême,soirée, animation",
        canonical : "http://www.bestexemple.com/canonical",
        og_locale : "en_GB",
        og_locale_alternate : "fr_fr",
        og_type : "article",
        og_title : "The Art of Python Web Development: Crafting Dynamic Digital Experiences",
        og_description : "Dive into the World of Python Web Development and Unleash the Power of Creativity and Functionality in Every Line of Code.",
        og_url : "https://www.thisisatest.com",
        og_image_url : "https://www.bestexemple.com/exempleimage.png",
        og_image_secure_url : "https://www.bestexemple.com/exempleimage.png",
        article_section : "Web development",
        article_publisher : "Test author",
        article_published_time : "2023-01-07T02:10:58.678Z",
        article_modified_time : "2023-01-07T02:10:58.678Z",
        twitter_title : "The Art of Python Web Development: Crafting Dynamic Digital Experiences",
        twitter_description : "Dive into the World of Python Web Development and Unleash the Power of Creativity and Functionality in Every Line of Code.",
        twitter_site : "@testuser",
        twitter_card : "summuary_test",
        twitter_image : "https://www.bestexemple.com/exempleimage.png",
        twitter_creator : "@testuser",
        twitter_cta : "En savoir plus",
        twitter_label_1 : "Ecrit par",
        twitter_data_1 : "DJ-Tdy Team",
        twitter_label_2 : "Temps de lecture",
        twitter_data_2 : "10 minutes",
    })
    const [optionPackModal, setOptionPackModal] = useState({
        display : false,
        variation : "ok",
        title: "",
        content: "",
        subContent: "",
        type: ""
    })
    const [searchQuery, setSearchQuery] = useState("")
    const formulaFilteredItems = data?.formulas ? data.formulas.filter((formula) =>
        formula.title.toLowerCase().includes(searchQuery.toLowerCase())
      ) : null
    const simpleOptionFilteredItems = data?.simple_options ? data.simple_options.filter((simpleOption) =>
        simpleOption.title.toLowerCase().includes(searchQuery.toLowerCase())
    ) : null
    const OptionPackFilteredItems = data?.option_packs ? data.option_packs.filter((optionPack) =>
        optionPack.title.toLowerCase().includes(searchQuery.toLowerCase())
    ) : null

    const fetchData = useCallback(async () => {
        await axiosProvider.get("/shop/formulas-packs/").then((response) => {
            setData(response.data);
        }).catch((error) => {
            navigate("/404");
        });
    }, [navigate]);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, [fetchData]);

    const checkTheme = () => {
        return theme === "light" ? "var(--regular-white)" : "var(--dark-grey)";
    };

    const handleSearch = (e) => {
        e.preventDefault();
        let query = e.target.elements.search.value
        setSearchQuery(query)
    }

    const toggleModal = (pack) => {
        setPack({...pack})
        setOptionPackModal({
            display : true,
            variation : "ok",
            title: pack.title,
            content: "",
            subContent: "",
            type: "basic"
        })
    }

    return (
        <>
            {data ? (
                <>
                    {/*-------------------------------- SEO --------------------------------*/}
                    <Metadata metadata={metadata} type="page" />
                    {/*-------------------------------- Banner --------------------------------*/}
                    <div className="container-x-small bg-white" ref={scrollRef}>
                        <div className="ban-img-wrapper-small">
                            <motion.div variants={imageZoomVariants} initial={"initial"} whileInView={'animate'} transition={{duration: 100, repeat: Infinity,}}  className="ban-img">
                                <img className="img-cover" src="/images/platine-2.jpg" alt="Banner" />
                            </motion.div>
                        </div>
                        <div className="ban-content-small">
                            <div className="gutter-large">
                                <div className="grid-1-2">
                                    <div className="grid-item">
                                        <div className="full-width">
                                            <h1 className="big-text bold-text white-text pad-b-m">Formules et options</h1>
                                            <p className="large-text light-text white-text pad-b-xl">Les formules et options sont là pour aider à choisir la manière dont votre évènement sera présenté et immortalisé, choisissez la formule qui vous correspond pour passer un moment inoubliable.</p>
                                        </div>
                                    </div>
                                    <div className="grid-item">
                                        <div className="full-width">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="back-container">
                            <svg xmlns="http://www.w3.org/2000/svg" id="back-shape" viewBox="0 0 1920 780.981">
                                <g id="shape_normal" transform="translate(0 -300)">
                                    <path id="last" d="M0-214.839s873.847,174.089,1353.847-21.4S1920-916.781,1920-916.781V-135.8H0Z" transform="translate(0 1216.781)" opacity="0.47"/>
                                    <path id="second" d="M0-214.839s873.847,99.089,1353.847-21.4S1920-806.781,1920-806.781V-135.8H0Z" transform="translate(0 1216.781)" opacity="0.65"/>
                                    <path id="first" d="M0-214.839s873.847,99.089,1353.847-21.4S1920-696.781,1920-696.781V-135.8H0Z" transform="translate(0 1216.781)" fill={checkTheme()}/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    {/*-------------------------------- Formula and options --------------------------------*/}
                    <div className="container bg-white">
                        <div className="spacer"></div>
                        <div className="flex-col-center pad-b-xl">
                            <SearchBar size={"medium"} color={"sand"} handler={handleSearch}/>
                        </div>
                        <div className="flex-col-center pad-b-xl">
                            <div className="gutter full-width">
                                <div className="flex-row-center pad-t-l pad-b-xl">
                                    <div className="icon-medium bg-icon-black">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                            <path
                                                d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z" />
                                        </svg>
                                    </div>
                                    <h2 className="big-text bold-text black-text pad-l-m">Les formules</h2>
                                </div>
                                <div className="fancy-card-wrapper">
                                    <div className="fancy-card-list">
                                        {formulaFilteredItems.length > 0 ? formulaFilteredItems.map((formula, i) => {
                                            return (
                                                <div className="grid-item" key={i}>
                                                    <div className="fancy-card-od">
                                                        <Link to={`/formules/${formula.slug}`} className="fancy-card-head fancy-card-head-blue">
                                                            <p className="big-text bold-text white-text">{formula.title}</p>
                                                        </Link>
                                                        <div className="fancy-card-content">
                                                            <div className="flex-col-center">
                                                                <div className="gutter-small">
                                                                    <p className="big-text bold-text blue-text">{formula.pack}</p>
                                                                </div>
                                                            </div>
                                                            <div className="flex-col-start">
                                                                <ul className="fancy-item-list">
                                                                    {formula.items.map((item, i) => {
                                                                        return (
                                                                            <li className="fancy-card-item" key={i}>
                                                                                <div className="circle-dot circle-blue"></div>
                                                                                <p className="large-text regular-text r-black-text pad-l-s">
                                                                                    <b>{item.quantity}</b> {item.title}
                                                                                </p>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="fancy-card-footer pad-b-l">
                                                            <p className="medium-text regular-text r-black-text pad-b-s">à partir de</p>
                                                            <p className="big-text bold-text blue-text">{formula.price} €</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : (
                                            <div className="flex-col-center">
                                                <p className="large-text regular-text center-text black-text">Aucune formules ne correspond à la recherche</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-col-center pad-b-xl">
                            <div className="gutter">
                                <div className="flex-row-center pad-t-l pad-b-xl">
                                    <div className="icon-medium bg-icon-black">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 590 512">
                                            <path d="M0 32C0 14.3 14.3 0 32 0H160c17.7 0 32 14.3 32 32V416c0 53-43 96-96 96s-96-43-96-96V32zM223.6 425.9c.3-3.3 .4-6.6 .4-9.9V154l75.4-75.4c12.5-12.5 32.8-12.5 45.3 0l90.5 90.5c12.5 12.5 12.5 32.8 0 45.3L223.6 425.9zM182.8 512l192-192H480c17.7 0 32 14.3 32 32V480c0 17.7-14.3 32-32 32H182.8zM128 64H64v64h64V64zM64 192v64h64V192H64zM96 440a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/>
                                        </svg>
                                    </div>
                                    <h2 className="big-text bold-text black-text pad-l-m">Les options simples</h2>
                                </div>
                            </div>
                            <div className="fancy-small-card-wrapper">
                                <div className="fancy-small-card-list">
                                    {simpleOptionFilteredItems.length > 0 ? simpleOptionFilteredItems.map((option, i) => {
                                        return (
                                            <div className="grid-item" key={i}>
                                                <div className="fancy-small-card">
                                                    <div className="fancy-small-card-content">
                                                        <p className="medium-text bold-text r-black-text">{option.title}</p>
                                                    </div>
                                                    <div className="fancy-small-card-footer">
                                                        <p className="large-text bold-text white-text" >{option.price} €</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : (
                                        <div className="flex-col-center full-width">
                                            <p className="large-text regular-text center-text black-text">Aucune option simple ne correspond à la recherche</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex-col-center pad-b-xl">
                            <div className="flex-row-center pad-t-l pad-b-xl">
                                <div className="icon-medium bg-icon-black">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                        <path d="M58.9 42.1c3-6.1 9.6-9.6 16.3-8.7L320 64 564.8 33.4c6.7-.8 13.3 2.7 16.3 8.7l41.7 83.4c9 17.9-.6 39.6-19.8 45.1L439.6 217.3c-13.9 4-28.8-1.9-36.2-14.3L320 64 236.6 203c-7.4 12.4-22.3 18.3-36.2 14.3L37.1 170.6c-19.3-5.5-28.8-27.2-19.8-45.1L58.9 42.1zM321.1 128l54.9 91.4c14.9 24.8 44.6 36.6 72.5 28.6L576 211.6v167c0 22-15 41.2-36.4 46.6l-204.1 51c-10.2 2.6-20.9 2.6-31 0l-204.1-51C79 419.7 64 400.5 64 378.5v-167L191.6 248c27.8 8 57.6-3.8 72.5-28.6L318.9 128h2.2z"/>
                                    </svg>
                                </div>
                                <h2 className="big-text bold-text black-text pad-l-m">Les packs d'options</h2>
                            </div>
                            <div className="fancy-card-simple-wrapper">
                                <div className="fancy-card-simple-list">
                                    {OptionPackFilteredItems.length > 0 ? OptionPackFilteredItems.map((pack,i) => {
                                        return (
                                            <div className="grid-item" key={i}>
                                                <div className="fancy-card-simple" onClick={() => toggleModal(pack)}>
                                                    <div className="fancy-card-simple-header">
                                                        <div className="flex-col-center pad-b-xl">
                                                            <p className="big-text bold-text blue-text">{pack.title}</p>
                                                        </div>
                                                    </div>
                                                    <div className="fancy-card-simple-content pad-b-xl">
                                                        <div className="flex-col-start">
                                                            <ul className="fancy-item-list">
                                                                {pack.items.map((item, m) => {
                                                                    return (
                                                                        <li className="fancy-card-item" key={m}>
                                                                            <div className="circle-dot circle-blue"></div>
                                                                            <p className="large-text bold-text r-black-text pad-l-s pad-r-s">{item.quantity}</p>
                                                                            <p className="large-text regular-text r-black-text pad-l-s">{item.simple_option.title}</p>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="fancy-card-simple-footer pad-b-xl">
                                                        <p className="big-text bold-text blue-text">{pack.price} €</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : (
                                        <div className="flex-col-center full-with">
                                            <p className="large-text regular-text center-text black-text">Aucun option pack ne correspond à la recherche</p>
                                        </div>
                                    )}
                                    <Modal data={optionPackModal} setter={setOptionPackModal}>
                                        <div className="modal-content">
                                            <div className="modal-content-inner">
                                                {pack ? (
                                                    <>
                                                    <p className="large-text regular-text black-text pad-b-l">{pack.description}</p>
                                                    <h3 className="big-text bold-text black-text pad-b-m">Avantages</h3>
                                                    <ul className="fancy-item-list">                                                                
                                                        {pack.items ? pack.items.map((item,i) => (
                                                            <li className="fancy-card-item" key={i}>
                                                                <div className="circle-dot circle-blue"></div>
                                                                <p className="large-text bold-text black-text pad-l-m pad-r-s">{item.quantity}</p>
                                                                <p className="large-text regular-text black-text pad-l-s">{item.simple_option.title}</p>
                                                            </li>
                                                        )): ""}
                                                    </ul>
                                                    <p className="big-text bold-text center-text blue-text pad-t-l">{pack.price} €</p>
                                                    </>
                                                ) : ""}
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                        <div className="spacer"></div>
                    </div>
                </>

            ):(
                <>
                    <div className="container-primary bg-white">
                        <div className="flex-full-center full-height">
                            <div className="icon-big">
                                <motion.svg xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 422 422"
                                animate={{rotate:[0, 360] }}
                                transition={{
                                    type: "linear",
                                    duration: 0.5, 
                                    repeat: Infinity
                                }}>
                                    <g id="loading_icon" transform="translate(4730 -3101)">
                                        <path className="loading" id="Subtraction_2" data-name="Subtraction 2" d="M436.543,212.707h0l-72.556-.1c.008-.575.012-1.179.012-1.795A128.8,128.8,0,0,0,275.063,88.184V13.247l.422.086.134.027a200.468,200.468,0,0,1,72.07,30.326,202.154,202.154,0,0,1,73.023,88.677A200.476,200.476,0,0,1,432.457,170.2a203.034,203.034,0,0,1,4.1,40.62c0,.62,0,1.255-.009,1.889Z" transform="translate(-4744.552 3094.183)" />
                                        <circle id="Ellipse_25" data-name="Ellipse 25" cx="211" cy="211" r="211" transform="translate(-4730 3101)" fill="none"/>
                                    </g>
                                </motion.svg>
                            </div>
                        </div>
                    </div>
                </>
            )}
            
        </>
    )
}

export default Formulas