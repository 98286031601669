import React, { useContext, useEffect, useRef, useState } from 'react'
import { motion } from "framer-motion";
import { ThemeContext } from "../../App";
import Metadata from "../../components/metadata";
import { imageZoomVariants } from '../../components/motionVariant';


const ConfidentialPolicy = () => {
    const scrollRef = useRef(null);
    const { theme } = useContext(ThemeContext);
    const [metadata] = useState({
        title : "Politque de confidentialités- Les politques de notre site",
        description : "Dive into the World of Python Web Development and Unleash the Power of Creativity and Functionality in Every Line of Code.",
        generator : "Made by fujyn",
        keywords : "DJ,Dj à domicile,Fête,Mariages,Baptême,soirée, animation",
        canonical : "http://www.bestexemple.com/canonical",
        og_locale : "en_GB",
        og_locale_alternate : "fr_fr",
        og_type : "article",
        og_title : "The Art of Python Web Development: Crafting Dynamic Digital Experiences",
        og_description : "Dive into the World of Python Web Development and Unleash the Power of Creativity and Functionality in Every Line of Code.",
        og_url : "https://www.thisisatest.com",
        og_image_url : "https://www.bestexemple.com/exempleimage.png",
        og_image_secure_url : "https://www.bestexemple.com/exempleimage.png",
        article_section : "Web development",
        article_publisher : "Test author",
        article_published_time : "2023-01-07T02:10:58.678Z",
        article_modified_time : "2023-01-07T02:10:58.678Z",
        twitter_title : "The Art of Python Web Development: Crafting Dynamic Digital Experiences",
        twitter_description : "Dive into the World of Python Web Development and Unleash the Power of Creativity and Functionality in Every Line of Code.",
        twitter_site : "@testuser",
        twitter_card : "summuary_test",
        twitter_image : "https://www.bestexemple.com/exempleimage.png",
        twitter_creator : "@testuser",
        twitter_cta : "En savoir plus",
        twitter_label_1 : "Ecrit par",
        twitter_data_1 : "DJ-Tdy Team",
        twitter_label_2 : "Temps de lecture",
        twitter_data_2 : "10 minutes",
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const checkTheme = () => {
        return theme === "light" ? "var(--regular-white)" : "var(--dark-grey)";
    };

    return (
        <>
            {/*-------------------------------- SEO --------------------------------*/}
            <Metadata metadata={metadata} type="page" />
            {/*-------------------------------- Banner --------------------------------*/}
            <div className="container-x-small bg-white" ref={scrollRef}>
                <div className="ban-img-wrapper-small">
                    <motion.div variants={imageZoomVariants} initial={"initial"} whileInView={'animate'} transition={{duration: 100, repeat: Infinity,}} className="ban-img">
                        <img className="img-cover" src="/images/platine-4.jpg" alt="Banner" />
                    </motion.div>
                </div>
                <div className="ban-content-small">
                    <div className="gutter-large">
                        <div className="grid-1-2">
                            <div className="grid-item">
                                <div className="full-width">
                                    <h1 className="big-text bold-text white-text pad-b-m">Politique de confidentialités</h1>
                                    <p className="large-text light-text white-text pad-b-xl">La politique de confidentialités est là pour aider à choisir la manière dont votre évènement sera présenté et immortalisé, choisissez la formule qui vous correspond pour passer un moment inoubliable.</p>
                                </div>
                            </div>
                            <div className="grid-item">
                                <div className="full-width">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="back-container">
                    <svg xmlns="http://www.w3.org/2000/svg" id="back-shape" viewBox="0 0 1920 780.981">
                        <g id="shape_normal" transform="translate(0 -300)">
                            <path id="last" d="M0-214.839s873.847,174.089,1353.847-21.4S1920-916.781,1920-916.781V-135.8H0Z" transform="translate(0 1216.781)" opacity="0.47"/>
                            <path id="second" d="M0-214.839s873.847,99.089,1353.847-21.4S1920-806.781,1920-806.781V-135.8H0Z" transform="translate(0 1216.781)" opacity="0.65"/>
                            <path id="first" d="M0-214.839s873.847,99.089,1353.847-21.4S1920-696.781,1920-696.781V-135.8H0Z" transform="translate(0 1216.781)" fill={checkTheme()}/>
                        </g>
                    </svg>
                </div>
            </div>
            {/*-------------------------------- Content --------------------------------*/}
            <div className="container bg-white">
                <div className="spacer"></div>
                <div className="gutter-large">
                    <p className="medium-text regular-text black-text pad-b-xl">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut mattis nisl augue, eu vehicula erat iaculis vitae. Donec a lorem aliquet, sodales nunc in, pulvinar nulla. Suspendisse potenti. Nunc tincidunt elit id diam dapibus, id feugiat justo volutpat. Ut in imperdiet sem. Nam vel vestibulum neque. Praesent nulla risus, rutrum eu mollis suscipit, molestie vitae dolor. Donec a neque nec lectus sollicitudin imperdiet eu maximus enim. Etiam quam nulla, sagittis at aliquet a, sollicitudin et urna. 
                    </p>
                    <div className="img-pres marg-b-xl">
                        <img className="img-cover" src="/images/platine.jpg" alt="Banner" />
                    </div>
                    <h2 className="big-text bold-text black-text pad-b-l">Quelles sont les points positif de cette évènements ?</h2>
                    <p className="medium-text regular-text black-text pad-b-m">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut mattis nisl augue, eu vehicula erat iaculis vitae. Donec a lorem aliquet, sodales nunc in, pulvinar nulla. Suspendisse potenti. Nunc tincidunt elit id diam dapibus, id feugiat justo volutpat. Ut in imperdiet sem. Nam vel vestibulum neque. Praesent nulla risus, rutrum eu mollis suscipit, molestie vitae dolor. Donec a neque nec lectus sollicitudin imperdiet eu maximus enim. Etiam quam nulla, sagittis at aliquet a, sollicitudin et urna. 
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut mattis nisl augue, eu vehicula erat iaculis vitae. Donec a lorem aliquet, sodales nunc in
                    </p>
                    <p className="medium-text regular-text black-text pad-b-xl">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut mattis nisl augue, eu vehicula erat iaculis vitae. Donec a lorem aliquet, sodales nunc in, pulvinar nulla. Suspendisse potenti. Nunc tincidunt elit id diam dapibus, id feugiat justo volutpat. Ut in imperdiet sem. Nam vel vestibulum neque. Praesent nulla risus, rutrum eu mollis suscipit, molestie vitae dolor. Donec a neque nec lectus sollicitudin imperdiet eu maximus enim. Etiam quam nulla, sagittis at aliquet a, sollicitudin et urna. 
                    </p>
                    <h2 className="big-text bold-text black-text pad-b-l">Pourquoi devriez-vous assister a cette évènement ?</h2>
                    <p className="medium-text regular-text black-text pad-b-m">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut mattis nisl augue, eu vehicula erat iaculis vitae. Donec a lorem aliquet, sodales nunc in, pulvinar nulla. Suspendisse potenti. Nunc tincidunt elit id diam dapibus, id feugiat justo volutpat. Ut in imperdiet sem. Nam vel vestibulum neque. Praesent nulla risus, rutrum eu mollis suscipit, molestie vitae dolor. Donec a neque nec lectus sollicitudin imperdiet eu maximus enim. Etiam quam nulla, sagittis at aliquet a, sollicitudin et urna. 
                    </p>
                    <div className="img-pres marg-b-xl">
                        <img className="img-cover" src="/images/platine.jpg" alt="Banner" />
                    </div>
                    <p className="medium-text regular-text black-text pad-b-m">
                        Nam imperdiet bibendum elit, a suscipit nisl hendrerit eu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Mauris a pretium orci, in maximus lorem. Phasellus malesuada diam a tristique ultrices. Integer ut luctus tellus. Donec sodales ultricies consequat. Ut pharetra bibendum purus a dignissim. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer posuere fermentum quam, at egestas nunc laoreet non. Ut purus arcu, lacinia ac eros sed, fermentum euismod odio.
                    </p>
                    <p className="medium-text regular-text black-text pad-b-xl">
                        Nam imperdiet bibendum elit, a suscipit nisl hendrerit eu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Mauris a pretium orci, in maximus lorem. Phasellus malesuada diam a tristique ultrices. Integer ut luctus tellus. Donec sodales ultricies consequat. Ut pharetra bibendum purus a dignissim. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer posuere fermentum quam, at egestas nunc laoreet non. Ut purus arcu, lacinia ac eros sed, fermentum euismod odio.
                    </p>
                    <h2 className="big-text bold-text black-text pad-b-l">Les moment inoubliable de cette évènement ?</h2>
                    <div className="img-pres marg-b-xl">
                        <img className="img-cover" src="/images/platine.jpg" alt="Banner" />
                    </div>
                    <p className="medium-text regular-text black-text pad-b-xl">
                        Nam imperdiet bibendum elit, a suscipit nisl hendrerit eu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Mauris a pretium orci, in maximus lorem. Phasellus malesuada diam a tristique ultrices. Integer ut luctus tellus. Donec sodales ultricies consequat. Ut pharetra bibendum purus a dignissim. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer posuere fermentum quam, at egestas nunc laoreet non. Ut purus arcu, lacinia ac eros sed, fermentum euismod odio.
                    </p>
                    <div className="img-pres marg-b-xl">
                        <img className="img-cover" src="/images/platine.jpg" alt="Banner" />
                    </div>
                    <p className="medium-text regular-text black-text pad-b-m">
                        Nam imperdiet bibendum elit, a suscipit nisl hendrerit eu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Mauris a pretium orci, in maximus lorem. Phasellus malesuada diam a tristique ultrices. Integer ut luctus tellus. Donec sodales ultricies consequat. Ut pharetra bibendum purus a dignissim. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer posuere fermentum quam, at egestas nunc laoreet non. Ut purus arcu, lacinia ac eros sed, fermentum euismod odio.
                    </p>
                </div>
            </div>
        </>
    )
}

export default ConfidentialPolicy