import React, { useRef, useContext, useEffect, useState, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { ThemeContext } from "../../App";
import axiosProvider from "../../core/axios";
import Metadata from "../../components/metadata";
import { imageZoomVariants } from "../../components/motionVariant";


const ArticleDetail = () => {
    const scrollRef = useRef(null);
    const navigate = useNavigate();
    const { slug } = useParams();
    const { theme } = useContext(ThemeContext);
    const [data, setData] = useState(null);
    

    const fetchData = useCallback(async () => {
        await axiosProvider.get(`/articles/${slug}`).then((response) => {
            setData(response.data);
        }).catch((error) => {
            navigate("/404");
        });
    }, [navigate, slug]);
    

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchData()
    }, [fetchData])

    const checkTheme = () => {
        return theme === "light" ? "var(--regular-white)" : "var(--dark-grey)";
    };

    const returnAction = () => {
        navigate("/articles");
    };

    return (
        <>
            {data ? (
                <>
                    {/*-------------------------------- SEO --------------------------------*/}
                    <Metadata metadata={data.metadata} type="article" />
                    {/*-------------------------------- Back button --------------------------------*/}
                    <div className="back-btn-wrapper" onClick={returnAction}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
                        </svg>
                    </div>
                    {/*-------------------------------- Banner --------------------------------*/}
                    <div className="container-x-small bg-white" ref={scrollRef}>
                        <div className="ban-img-wrapper-small">
                            <motion.div variants={imageZoomVariants} initial={"initial"} whileInView={'animate'} transition={{duration: 90, repeat: Infinity,}} className="ban-img">
                                <img className="img-cover" src={data.article.thumbnail ? data.article.thumbnail : "/images/platine.jpg"} alt="Banner" />
                            </motion.div>
                        </div>
                        <div className="ban-content-small">
                            <div className="gutter-large">
                                <div className="grid-1-2">
                                    <div className="grid-item">
                                        <div className="full-width">
                                            <h1 className="big-text bold-text white-text marg-b-l back-trans-black">{data.article.title}</h1>
                                            {data.article.categories.length > 0 ? data.article.categories.map((category, i) => {
                                                return (
                                                    <span className="tag tag-medium tag-great" key={i}>{category.title}</span>
                                                )
                                            }) : ""}                                            
                                        </div>
                                    </div>
                                    <div className="grid-item">
                                        <div className="full-width">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="back-container">
                            <svg xmlns="http://www.w3.org/2000/svg" id="back-shape" viewBox="0 0 1920 780.981">
                                <g id="shape_normal" transform="translate(0 -300)">
                                    <path id="last" d="M0-214.839s873.847,174.089,1353.847-21.4S1920-916.781,1920-916.781V-135.8H0Z" transform="translate(0 1216.781)" opacity="0.47"/>
                                    <path id="second" d="M0-214.839s873.847,99.089,1353.847-21.4S1920-806.781,1920-806.781V-135.8H0Z" transform="translate(0 1216.781)" opacity="0.65"/>
                                    <path id="first" d="M0-214.839s873.847,99.089,1353.847-21.4S1920-696.781,1920-696.781V-135.8H0Z" transform="translate(0 1216.781)" fill={checkTheme()}/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    {/*-------------------------------- Content --------------------------------*/}
                    <div className="container bg-white">
                        <div className="spacer"></div>
                        <div className="gutter-large">
                            <div className="black-text pad-b-l" dangerouslySetInnerHTML={{ __html: data.article.content }}>
                            </div>
                        </div>
                    </div>
                    {/*-------------------------------- Similar article --------------------------------*/}
                    <div className="container-small bg-white">
                        <div className="gutter">
                            <div className="flex-col-center pad-b-xl">
                                <div className="flex-row-center pad-t-l pad-b-xl">
                                    <div className="icon-medium bg-icon-black">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M96 96c0-35.3 28.7-64 64-64H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H80c-44.2 0-80-35.8-80-80V128c0-17.7 14.3-32 32-32s32 14.3 32 32V400c0 8.8 7.2 16 16 16s16-7.2 16-16V96zm64 24v80c0 13.3 10.7 24 24 24H296c13.3 0 24-10.7 24-24V120c0-13.3-10.7-24-24-24H184c-13.3 0-24 10.7-24 24zm208-8c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16s-7.2-16-16-16H384c-8.8 0-16 7.2-16 16zm0 96c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16s-7.2-16-16-16H384c-8.8 0-16 7.2-16 16zM160 304c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16s-7.2-16-16-16H176c-8.8 0-16 7.2-16 16zm0 96c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16s-7.2-16-16-16H176c-8.8 0-16 7.2-16 16z"/>
                                        </svg>
                                    </div>
                                    <h2 className="big-text bold-text black-text pad-l-m">Articles Similaires</h2>
                                </div>
                            </div>
                            <div className="grid-4">
                                {data.recommendation.map((article, i) => {
                                    return (
                                        <div className="grid-item" key={i}>
                                            <Link to={`/articles/${article.slug}`} className="mod-card">
                                                <div className="mod-card-header">
                                                    <img className="img-cover" src={article.thumbnail} alt={article.title} />
                                                </div>
                                                <div className="mod-card-content">
                                                    <p className="large-text bold-text r-black-text pad-b-m">{article.title}</p>
                                                    <div className="grid-3-5 pad-b-m">
                                                        {article.categories ? article.categories.map((category,i) => {
                                                            return (
                                                                <div className="grid-item-fit" key={i}>
                                                                    <span className="tag tag-small tag-great marg-r-s">{category.title}</span>
                                                                </div>
                                                            )
                                                        }) : ""}
                                                    </div>                                                 
                                                    <p className="medium-text regular-text r-black-text pad-b-s">{article.short_desc}</p>
                                                </div>
                                                <div className="mod-card-footer">
                                                    <p className="small-text bold-text r-black-text">{article.created_at}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="spacer"></div>
                    </div>
                </>
            ) : (
                <>
                    <div className="container-primary bg-white">
                        <div className="flex-full-center full-height">
                            <div className="icon-big">
                                <motion.svg xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 422 422"
                                animate={{rotate:[0, 360] }}
                                transition={{
                                    type: "linear",
                                    duration: 0.5, 
                                    repeat: Infinity
                                }}>
                                    <g id="loading_icon" transform="translate(4730 -3101)">
                                        <path className="loading" id="Subtraction_2" data-name="Subtraction 2" d="M436.543,212.707h0l-72.556-.1c.008-.575.012-1.179.012-1.795A128.8,128.8,0,0,0,275.063,88.184V13.247l.422.086.134.027a200.468,200.468,0,0,1,72.07,30.326,202.154,202.154,0,0,1,73.023,88.677A200.476,200.476,0,0,1,432.457,170.2a203.034,203.034,0,0,1,4.1,40.62c0,.62,0,1.255-.009,1.889Z" transform="translate(-4744.552 3094.183)" />
                                        <circle id="Ellipse_25" data-name="Ellipse 25" cx="211" cy="211" r="211" transform="translate(-4730 3101)" fill="none"/>
                                    </g>
                                </motion.svg>
                            </div>
                        </div>
                    </div>
                </>
            )}
            
        </>
    )
}

export default ArticleDetail