export const fadeInLeftAnimationVariants = {
    initial : {
        opacity: 0,
        x: -200
    },
    animate: {
        opacity:1,
        x: 0
    }
}

export const fadeInRightAnimationVariants = {
    initial : {
        opacity: 0,
        x: 200
    },
    animate: {
        opacity:1,
        x: 0
    }
}
export const fadeInBottomAnimationVariants = {
    initial : {
        opacity: 0,
        y: 100
    },
    animate: {
        opacity:1,
        y: 0
    }
}
export const imageZoomVariants = {
    initial : {
        width: '100%',
    },
    animate: {
        width: '140%'
    }
}