import React, { useRef, useContext, useEffect, useState, useCallback } from "react";
import { motion } from "framer-motion";
import { ThemeContext } from "../../App";
import SearchBar from "../../components/searchBar";
import { Link, useNavigate } from "react-router-dom";
import axiosProvider from "../../core/axios";
import Metadata from "../../components/metadata";
import { imageZoomVariants } from "../../components/motionVariant";


function Articles() {
    const scrollRef = useRef(null);
    const navigate = useNavigate();
    const { theme } = useContext(ThemeContext);
    const [data, setData] = useState(null);
    const [searchQuery, setSearchQuery] = useState("")
    const [metadata] = useState({
        title : "Actualités - Les actualités de Dj-Tdy",
        description : "Dive into the World of Python Web Development and Unleash the Power of Creativity and Functionality in Every Line of Code.",
        generator : "Made by fujyn",
        keywords : "DJ,Dj à domicile,Fête,Mariages,Baptême,soirée, animation",
        canonical : "http://www.bestexemple.com/canonical",
        og_locale : "en_GB",
        og_locale_alternate : "fr_fr",
        og_type : "article",
        og_title : "The Art of Python Web Development: Crafting Dynamic Digital Experiences",
        og_description : "Dive into the World of Python Web Development and Unleash the Power of Creativity and Functionality in Every Line of Code.",
        og_url : "https://www.thisisatest.com",
        og_image_url : "https://www.bestexemple.com/exempleimage.png",
        og_image_secure_url : "https://www.bestexemple.com/exempleimage.png",
        article_section : "Web development",
        article_publisher : "Test author",
        article_published_time : "2023-01-07T02:10:58.678Z",
        article_modified_time : "2023-01-07T02:10:58.678Z",
        twitter_title : "The Art of Python Web Development: Crafting Dynamic Digital Experiences",
        twitter_description : "Dive into the World of Python Web Development and Unleash the Power of Creativity and Functionality in Every Line of Code.",
        twitter_site : "@testuser",
        twitter_card : "summuary_test",
        twitter_image : "https://www.bestexemple.com/exempleimage.png",
        twitter_creator : "@testuser",
        twitter_cta : "En savoir plus",
        twitter_label_1 : "Ecrit par",
        twitter_data_1 : "DJ-Tdy Team",
        twitter_label_2 : "Temps de lecture",
        twitter_data_2 : "10 minutes",
    })
    const articleFilteredItems = data?.articles ? data.articles.filter((article) =>
        article.title.toLowerCase().includes(searchQuery.toLowerCase())
      ) : null

    const fetchData = useCallback(async () => {
        await axiosProvider.get("/articles/pack").then((response) => {
            setData(response.data);
        }).catch((error) => {
            navigate("/404");
        });
    }, [navigate]);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, [fetchData]);
    
    const checkTheme = () => {
        return theme === "light" ? "var(--regular-white)" : "var(--dark-grey)";
    };

    const handleSearch = (e) => {
        e.preventDefault();
        let query = e.target.elements.search.value
        setSearchQuery(query)
    }

    const cleanQuery = () => {
        const search = document.getElementById("search-input")
        setSearchQuery("")
        search.value = ""
    }

    return (
        <>
            {data ? (
                <>
                    {/*-------------------------------- SEO --------------------------------*/}
                    <Metadata metadata={metadata} type="page" />
                    {/*-------------------------------- Banner --------------------------------*/}
                    <div className="container-x-small bg-white" ref={scrollRef}>
                        <div className="ban-img-wrapper-small">
                            <motion.div variants={imageZoomVariants} initial={"initial"} whileInView={'animate'} transition={{duration: 100, repeat: Infinity,}} className="ban-img">
                                <img className="img-cover" src="/images/platine-9.jpg" alt="Banner" />
                            </motion.div>
                        </div>
                        <div className="ban-content-small">
                            <div className="gutter-large">
                                <div className="grid-1-2">
                                    <div className="grid-item">
                                        <div className="full-width">
                                            <h1 className="big-text bold-text white-text pad-b-m">Nos actualités</h1>
                                            <p className="large-text light-text white-text pad-b-xl">Les formules et options sont là pour aider à choisir la manière dont votre évènement sera présenté et immortalisé, choisissez la formule qui vous correspond pour passer un moment inoubliable.</p>
                                        </div>
                                    </div>
                                    <div className="grid-item">
                                        <div className="full-width">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="back-container">
                            <svg xmlns="http://www.w3.org/2000/svg" id="back-shape" viewBox="0 0 1920 780.981">
                                <g id="shape_normal" transform="translate(0 -300)">
                                    <path id="last" d="M0-214.839s873.847,174.089,1353.847-21.4S1920-916.781,1920-916.781V-135.8H0Z" transform="translate(0 1216.781)" opacity="0.47"/>
                                    <path id="second" d="M0-214.839s873.847,99.089,1353.847-21.4S1920-806.781,1920-806.781V-135.8H0Z" transform="translate(0 1216.781)" opacity="0.65"/>
                                    <path id="first" d="M0-214.839s873.847,99.089,1353.847-21.4S1920-696.781,1920-696.781V-135.8H0Z" transform="translate(0 1216.781)" fill={checkTheme()}/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    {/*-------------------------------- CONTENT --------------------------------*/}
                    <div className="container bg-white">
                        <div className="spacer"></div>
                        <div className="flex-col-center pad-b-xl">
                            <SearchBar size={"medium"} color={"sand"} handler={handleSearch}/>
                        </div>
                        <div className="gutter">
                            <div className="grid-4">
                                {articleFilteredItems.length > 0 ? articleFilteredItems.map((article, i) => {
                                    return (
                                        <div className="grid-item" key={i}>
                                            <Link to={`/articles/${article.slug}`} className="mod-card">
                                                <div className="mod-card-header">
                                                    <img className="img-cover" src={article.thumbnail ? article.thumbnail : "/images/platine.jpg"} alt={article.title} />
                                                </div>
                                                <div className="mod-card-content">
                                                    <p className="large-text bold-text r-black-text pad-b-m">{article.title}</p>
                                                    <div className="grid-3-5 pad-b-m">
                                                        {article.categories ? article.categories.map((category,i) => {
                                                            return (
                                                                <div className="grid-item-fit" key={i}>
                                                                    <span className="tag tag-small tag-great marg-r-s">{category.title}</span>
                                                                </div>
                                                            )
                                                        }) : ""}
                                                    </div>
                                                    <p className="medium-text regular-text r-black-text pad-b-s">{article.short_desc}</p>
                                                </div>
                                                <div className="mod-card-footer">
                                                    <p className="small-text bold-text r-black-text">{article.created_at}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                }) : (
                                    <div className="flex-col-center">
                                        <p className="large-text bold-text center-text black-text pad-b-m">Aucun article ne correspond à la recherche</p>
                                        <p className="large-text regular-text center-text black-text pad-b-l">Cliquer sur le bouton ci-dessous pour réinitialiser la recherche</p>
                                        <div className="btn btn-bordered btn-large btn-current" onClick={() => cleanQuery()}>Réinitialiser</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="spacer"></div>
                    </div>
                    {/*-------------------------------- RECOMMANDATION --------------------------------*/}
                    <div className="container bg-white">
                        <div className="flex-row-center pad-t-l pad-b-xl">
                            <div className="icon-medium bg-icon-black">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path
                                        d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z" />
                                </svg>
                            </div>
                            <h2 className="big-text bold-text black-text pad-l-m">Article recommandés</h2>
                        </div>
                        <div className="gutter">
                            <div className="grid-4">
                                {data.recommended.map((article, i) => {
                                    return (
                                        <div className="grid-item" key={i}>
                                            <Link to={`/articles/${article.slug}`} className="mod-card">
                                                <div className="mod-card-header">
                                                    <img className="img-cover" src={article.thumbnail ? article.thumbnail : "/images/platine.jpg"} alt={article.title} />
                                                </div>
                                                <div className="mod-card-content">
                                                    <p className="large-text bold-text r-black-text pad-b-m">{article.title}</p>
                                                    <div className="grid-3-5 pad-b-m">
                                                        {article.categories ? article.categories.map((category,i) => {
                                                            return (
                                                                <div className="grid-item-fit" key={i}>
                                                                    <span className="tag tag-small tag-great marg-r-s">{category.title}</span>
                                                                </div>
                                                            )
                                                        }) : ""}
                                                    </div>
                                                    <p className="medium-text regular-text r-black-text pad-b-s">{article.short_desc}</p>
                                                </div>
                                                <div className="mod-card-footer">
                                                    <p className="small-text bold-text r-black-text">{article.created_at}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="spacer"></div>
                    </div>
                </>
            ): (
                <>
                    <div className="container-primary bg-white">
                        <div className="flex-full-center full-height">
                            <div className="icon-big">
                                <motion.svg xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 422 422"
                                animate={{rotate:[0, 360] }}
                                transition={{
                                    type: "linear",
                                    duration: 0.5, 
                                    repeat: Infinity
                                }}>
                                    <g id="loading_icon" transform="translate(4730 -3101)">
                                        <path className="loading" id="Subtraction_2" data-name="Subtraction 2" d="M436.543,212.707h0l-72.556-.1c.008-.575.012-1.179.012-1.795A128.8,128.8,0,0,0,275.063,88.184V13.247l.422.086.134.027a200.468,200.468,0,0,1,72.07,30.326,202.154,202.154,0,0,1,73.023,88.677A200.476,200.476,0,0,1,432.457,170.2a203.034,203.034,0,0,1,4.1,40.62c0,.62,0,1.255-.009,1.889Z" transform="translate(-4744.552 3094.183)" />
                                        <circle id="Ellipse_25" data-name="Ellipse 25" cx="211" cy="211" r="211" transform="translate(-4730 3101)" fill="none"/>
                                    </g>
                                </motion.svg>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default Articles