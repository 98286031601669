import React from "react";


const COLORS = ['current','great','sand','danger']
const SIZES = ['medium', 'small', 'large']

const SearchBar = ({size, color, handler}) => {

  const checkSize = SIZES.includes(size) ? size : SIZES[0]
  const checkColor = COLORS.includes(color) ? color : COLORS[0]

  const handleSearch = (e) => {
    handler(e)
  };

  return (
    <>
      <form className="search-wrapper" onSubmit={handleSearch}>
        <input
          id="search-input"
          className={`search-input search-input-${checkSize} search-input-${checkColor}`}
          type="text"
          name="search"
          placeholder="Recherche" 
        />
        <button type="submit" className={`search-icon search-icon-${checkSize} search-icon-${checkColor} marg-l-s`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
            </svg>
        </button>
      </form>
    </>
  );
};

export default SearchBar;