import React from "react";
import { Helmet } from "react-helmet-async";

const TYPE = ['page', 'article'];

const Metadata = ({ metadata, type }) => {
    const checkType = TYPE.includes(type) ? type : TYPE[0]

    return (
        <>
            <Helmet>
                <title>{metadata.title}</title>
                <meta name="title" content={metadata.title} />
                <meta name="description" content={metadata.description} />
                <meta name="generator" content={metadata.generator} />
                <meta name="author" content="Dj-Tdy Team" />
                <meta name="keywords" content={metadata.keywords} />
                <meta name="robots" content={metadata.robots} />
                <link rel="canonical" href={metadata.canonical} />
                {/* Other Social Media Meta Tags */}
                <meta property="og:locale" content={metadata.og_locale} />
                <meta property="og:locale:alternate" content={metadata.og_locale_alternate} />
                <meta property="og:type" content={metadata.og_type} />
                <meta property="og:title" content={metadata.og_title} />
                <meta property="og:description" content={metadata.og_description} />
                <meta property="og:site_name" content={metadata.og_site_name} />
                <meta property="og:url" content={metadata.og_url} />
                <meta property="og:image:url" content={metadata.og_image_url} />
                <meta property="og:image:secure_url" content={metadata.og_image_secure_url} />                
                {/* Twitter Meta Tags */}
                <meta name="twitter:title" content={metadata.twitter_title} />
                <meta name="twitter:description" content={metadata.txitter_description} />
                <meta name="twitter:card" content={metadata.twitter_card} />
                <meta name="twitter:image:src" content={metadata.twitter_image} />
                <meta name="twitter:creator" content={metadata.twitter_creator} />
                <meta name="twitter:url" content={window.location.href} />
                <meta name="twitter:cta" content={metadata.twitter_cta} />
                {checkType === "article" ? (
                    <>
                        <meta property="article:section" content={metadata.article_section} />
                        <meta property="article:publisher" content={metadata.article_publisher} />
                        <meta property="article:published_time" content={metadata.article_published_time} />
                        <meta property="article:modified_time" content={metadata.article_modified_time} />
                        <meta name="twitter:label1" content={metadata.twitter_label_1} />
                        <meta name="twitter:data1" content={metadata.twitter_data_1}/>
                        <meta name="twitter:label2" content={metadata.twitter_label_2} />
                        <meta name="twitter:data2" content={metadata.twitter_data_2} />
                    </>
                ) : ""}
            </Helmet>
        </>
    );
};

export default Metadata;
