import React, { useRef, useContext, useEffect, useState, useCallback } from "react";
import { motion } from "framer-motion";
import { ThemeContext } from "../../App";
import axiosProvider from "../../core/axios";
import ImageSlider from "../../components/imageSlider";
import Metadata from "../../components/metadata";
import { useNavigate } from "react-router";
import { imageZoomVariants } from "../../components/motionVariant";


const Galery = () => {
    const scrollRef = useRef(null);
    const navigate = useNavigate()
    const { theme } = useContext(ThemeContext);
    const [data, setData] = useState(null);
    const [metadata] = useState({
        title : "Galerie - Les moments de Dj-Tdy",
        description : "Dive into the World of Python Web Development and Unleash the Power of Creativity and Functionality in Every Line of Code.",
        generator : "Made by fujyn",
        keywords : "DJ,Dj à domicile,Fête,Mariages,Baptême,soirée, animation",
        canonical : "http://www.bestexemple.com/canonical",
        og_locale : "en_GB",
        og_locale_alternate : "fr_fr",
        og_type : "article",
        og_title : "The Art of Python Web Development: Crafting Dynamic Digital Experiences",
        og_description : "Dive into the World of Python Web Development and Unleash the Power of Creativity and Functionality in Every Line of Code.",
        og_url : "https://www.thisisatest.com",
        og_image_url : "https://www.bestexemple.com/exempleimage.png",
        og_image_secure_url : "https://www.bestexemple.com/exempleimage.png",
        article_section : "Web development",
        article_publisher : "Test author",
        article_published_time : "2023-01-07T02:10:58.678Z",
        article_modified_time : "2023-01-07T02:10:58.678Z",
        twitter_title : "The Art of Python Web Development: Crafting Dynamic Digital Experiences",
        twitter_description : "Dive into the World of Python Web Development and Unleash the Power of Creativity and Functionality in Every Line of Code.",
        twitter_site : "@testuser",
        twitter_card : "summuary_test",
        twitter_image : "https://www.bestexemple.com/exempleimage.png",
        twitter_creator : "@testuser",
        twitter_cta : "En savoir plus",
        twitter_label_1 : "Ecrit par",
        twitter_data_1 : "DJ-Tdy Team",
        twitter_label_2 : "Temps de lecture",
        twitter_data_2 : "10 minutes",
    })
    const [imageModal, setImageModal] = useState({
        display : false,
        variation : "button",
        item: ""
    })

    const fetchData = useCallback(async () => {
        await axiosProvider.get("/core/galeries").then((response) => {
            setData(response.data);
        }).catch((error) => {
            navigate("/404");
        });
    }, [navigate]);

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchData()
    }, [fetchData])

    const splitIntoGroups = (array, groupSize) => {
        const groups = [];
        for (let i = 0; i < array.length; i += groupSize) {
          groups.push(array.slice(i, i + groupSize));
        }
        return groups;
    };

    const toggleImageSlider = (item) => {
        setImageModal({
            display : true,
            variation : "button",
            item: item
        })
    }
    

    const checkTheme = () => {
        return theme === "light" ? "var(--regular-white)" : "var(--dark-grey)";
    };

    return (
        <>
            {data ? (
                <>
                    {/*-------------------------------- SEO --------------------------------*/}
                    <Metadata metadata={metadata} type="page" />
                    {/*-------------------------------- Banner --------------------------------*/}
                    <div className="container-x-small bg-white" ref={scrollRef}>
                        <div className="ban-img-wrapper-small">
                            <motion.div variants={imageZoomVariants} initial={"initial"} whileInView={'animate'} transition={{duration: 100, repeat: Infinity,}} className="ban-img">
                                <img className="img-cover" src="/images/platine-3.jpg" alt="Banner" />
                            </motion.div>
                        </div>
                        <div className="ban-content-small">
                            <div className="gutter-large">
                                <div className="grid-1-2">
                                    <div className="grid-item">
                                        <div className="full-width">
                                            <h1 className="big-text bold-text white-text pad-b-m">Galerie</h1>
                                            <p className="large-text light-text white-text pad-b-xl">La galerie est la partie où nous partageons les moments mémorable auxquelles nous avons participer.</p>
                                        </div>
                                    </div>
                                    <div className="grid-item">
                                        <div className="full-width">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="back-container">
                            <svg xmlns="http://www.w3.org/2000/svg" id="back-shape" viewBox="0 0 1920 780.981">
                                <g id="shape_normal" transform="translate(0 -300)">
                                    <path id="last" d="M0-214.839s873.847,174.089,1353.847-21.4S1920-916.781,1920-916.781V-135.8H0Z" transform="translate(0 1216.781)" opacity="0.47"/>
                                    <path id="second" d="M0-214.839s873.847,99.089,1353.847-21.4S1920-806.781,1920-806.781V-135.8H0Z" transform="translate(0 1216.781)" opacity="0.65"/>
                                    <path id="first" d="M0-214.839s873.847,99.089,1353.847-21.4S1920-696.781,1920-696.781V-135.8H0Z" transform="translate(0 1216.781)" fill={checkTheme()}/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    {/*-------------------------------- Pictures --------------------------------*/}
                    <div className="container bg-white">
                        <div className="spacer"></div>
                        <div className="gutter">
                            {splitIntoGroups(data, 10).map((group, i) => {
                                return (
                                    <div className="grid-mixed" key={i}>
                                        {group.map((item, i) => {
                                            return (
                                                <div key={i} className={`mixed-${i+1}`} onClick={() => toggleImageSlider(item)}>
                                                    <div className={i === 0 || i === 7 ? "card-img-mixed-1" : "card-img-mixed"}>
                                                        <img className="img-cover" src={item.media} alt={item.alt} />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })} 
                        </div>
                        <ImageSlider images={data} data={imageModal} setter={setImageModal} />
                        <div className="spacer"></div>
                    </div>
                </>
            ) : (
                <>
                    <div className="container-primary bg-white">
                        <div className="flex-full-center full-height">
                            <div className="icon-big">
                                <motion.svg xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 422 422"
                                animate={{rotate:[0, 360] }}
                                transition={{
                                    type: "linear",
                                    duration: 0.5, 
                                    repeat: Infinity
                                }}>
                                    <g id="loading_icon" transform="translate(4730 -3101)">
                                        <path className="loading" id="Subtraction_2" data-name="Subtraction 2" d="M436.543,212.707h0l-72.556-.1c.008-.575.012-1.179.012-1.795A128.8,128.8,0,0,0,275.063,88.184V13.247l.422.086.134.027a200.468,200.468,0,0,1,72.07,30.326,202.154,202.154,0,0,1,73.023,88.677A200.476,200.476,0,0,1,432.457,170.2a203.034,203.034,0,0,1,4.1,40.62c0,.62,0,1.255-.009,1.889Z" transform="translate(-4744.552 3094.183)" />
                                        <circle id="Ellipse_25" data-name="Ellipse 25" cx="211" cy="211" r="211" transform="translate(-4730 3101)" fill="none"/>
                                    </g>
                                </motion.svg>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default Galery