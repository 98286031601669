import React, { useEffect, useState } from 'react'
import { motion } from "framer-motion";

const VARIATION = ['okcancel', 'submit', 'ok'];
const TYPE = ['success', 'error', 'basic'];

const Modal = ({data, setter, children}) => {
    const checkVariation = VARIATION.includes(data.variation) ? data.variation : VARIATION[0]
    const checkType = TYPE.includes(data.type) ? data.type : TYPE[0]

    const closeModal = () => {
        setter({
            display: false
        })
    }

    const checkStyleType = () => {
        switch(checkType){
            case 'success':
                return (
                    <>
                        <div className='modal-icon-large modal-icon-success'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 520 520">
                                <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/>
                            </svg>
                        </div>
                    </>
                )
            case 'error':
                return (
                    <>
                        <div className='modal-icon-large modal-icon-error'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 520 520">
                                <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/>
                            </svg>
                        </div>
                    </>
                )
            case 'basic':
                return (
                    <>
                    </>
                )
        }
    }
    const checkButton = () => {
        switch(checkVariation){
            case 'okcancel':
                return (
                    <>
                        <button className='btn btn-default btn-bordered btn-current' onClick={closeModal}>Annuler</button>
                        <button className='btn btn-default btn-bordered btn-current'>Ok</button>
                    </>
                );
            case 'submit':
                return (
                    <>
                        <button className='btn btn-default btn-bordered btn-current' onClick={closeModal}>Annuler</button>
                        <button type='submit' className='btn btn-default btn-bordered btn-current'>Envoyer</button>
                    </>
                );
            case 'ok':
                return (
                    <>
                        <button className='btn btn-default btn-large btn-bordered btn-current' onClick={closeModal}>ok</button>
                    </>
                );
        }
    }

    return (
        <>
            <motion.div id="modal-overlay" style={data.display ? {display:'block'} : {display: 'none'}} className='modal-overlay'></motion.div>
            <motion.div id="modal" style={data.display ? {display:'flex'} : {display: 'none'}} className='modal-wrapper'>
                {checkVariation === 'submit' ? 
                    (
                        <form className='modal'>
                            <div className='modal-header'>
                                <p className='big-text bold-text black-text pad-r-m'>
                                    {data.title ? data.title : ""}
                                </p>
                                <div className='modal-icon-small modal-icon-basic modal-close' onClick={closeModal}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                        <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                                    </svg>
                                </div>
                            </div>
                            <div className='modal-content'>
                                {checkStyleType()}
                                <p className='medium-text bold-text black-text pad-b-m'>{data.content}</p>
                                <p className='medium-text regular-text black-text pad-b-m'>{data.subContent}</p>
                                {children}
                            </div>
                            <div className='modal-footer'>
                                {checkButton()}
                            </div>
                        </form>
                    ):
                    (
                        <div className='modal'>
                            <div className='modal-header'>
                                <p className='big-text bold-text black-text pad-r-m'>
                                    {data.title ? data.title : ""}
                                </p>
                                <div className='modal-icon-small modal-icon-basic modal-close' onClick={closeModal}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                                    </svg>
                                </div>
                            </div>
                            <div className='modal-content'>
                                <div className='flex-col-center pad-b-m'>
                                    {checkStyleType()}
                                </div>
                                {data.content ? <p className='medium-text bold-text black-text center-text pad-b-m'>{data.content}</p> : ""}
                                {data.subContent ? <p className='medium-text regular-text black-text center-text pad-b-m'>{data.subContent}</p> : ""}
                                {children}
                            </div>
                            <div className='modal-footer'>
                                {checkButton()}
                            </div>
                        </div>
                    )
                }
                
            </motion.div>
        </>
    )
}

export default Modal