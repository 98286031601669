import React from 'react'
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';

const Step5 = () => {
    return (
        <>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ type: "linear", duration: 0.2 }} className="container bg-white">
            <div className='spacer'></div>
            <div className='flex-col-start'>
                <div className='flex-col-center pad-b-l'>
                    <div className='icon-big bg-icon-green'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 520 520">
                            <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/>
                        </svg>
                    </div>
                </div>
                <div className='flex-col-center'>
                    <p className='big-text bold-text black-text pad-b-l'>
                        Félicitation votre demande a bien été enregistrer !
                    </p>
                    <p className='medium-text regular-text black-text pad-b-xl'>
                        Un mail récapitulatif vous a été envoyé avec les informations sur la suite du processus.
                    </p>
                    <Link to="/" className="btn btn-bordered btn-large btn-current">Retour à l'accueil</Link>
                </div>
            </div>
        </motion.div>
        </>
    )
}

export default Step5